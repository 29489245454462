import React from 'react'
import NoMatch from '../Components/404Page'
import '../App.css'
import List from '../Data/ClientList.json'
import androidPng from '../Images/google_play_store.png'
import iosPng from '../Images/apple_app_store.png'
import webPng from '../Images/web_app.png'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

export default class ClientList extends React.Component {
  renderClientList () {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={NoMatch} />
          {
            List.map((client) => {
              return (
                <Route key={client.name} exact path={client.path}>
                  <div className='App'>
                    {this.logo(client)}
                    <div className='container'>
                      <div className='row'>
                        <div className='stepsContainer'>
                          <div>
                            <h2 className='Download'>Download the {client.appName} App</h2>
                          </div>
                        </div>
                        {this.appIcons(client)}
                        {this.customText(client)}
                        {this.customImage(client)}
                        {this.customerCareEmail(client)}
                      </div>
                    </div>
                    <div>
                      {this.privacyPolicy()}
                    </div>
                    {this.footer()}
                  </div>
                </Route>
              )
            })
            }
          <Route path='/' component={NoMatch} />
        </Switch>
      </Router>
    )
  }

  showCurrentYear () {
    return new Date().getFullYear()
  }

  logo (client) {
    if (client.logoColor) {
      return (
        <div className='App-header' style={{ background: client.logoColor }}>
          <img className='logo' src={client.logo} alt={client.name + ' logo'} />
        </div>
      )
    } else {
      return (
        <div className='App-header'>
          <img className='logo' src={client.logo} alt={client.name + ' logo'} />
          <hr />
        </div>
      )
    }
  }

  appIcons (client) {
    return (
      <div className='row'>
        <div className='s12'>
          <div className='s4 iconLink'>
            {
        client.androidLink
          ? <a href={client.androidLink}><img className='iconLink' src={androidPng} alt='android' /></a>
          : ''
       }
            {
        client.iosLink
          ? <a href={client.iosLink}><img className='iconLink' src={iosPng} alt='ios' /></a>
          : ''
        }
            {
        client.webLink
          ? <a href={client.webLink}><img className='iconLink' src={webPng} alt='web' /></a>
          : ''
      }
          </div>
        </div>
      </div>
    )
  }

  customerCareEmail (client) {
    return (
      <div>
        <br />
        <small>
          <p>Should you experience any difficulties downloading the app, please write us at <a className='customerCare' href={'mailto:' + client.customerCareEmail}>{client.customerCareEmail}</a></p>
        </small>
      </div>
    )
  }

  privacyPolicy () {
    return (
      <small>
        <p>Click <a class='privacyPolicy' href='https://app.theemployeeapp.com/signup/privacy.html'>here</a> to view the app Privacy Policy</p>
      </small>
    )
  }

  customText (client) {
    const text = client.customText
    if (text) {
      return (
        <div>
          <br />
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <br />
        </div>
      )
    }
  }

  footer () {
    return (
      <div className='row center'>
        <p>
          <small>
            &copy; {this.showCurrentYear()} theEMPLOYEEapp&reg;
          </small>
        </p>
      </div>
    )
  }

  customImage (client) {
    if (client.customImage) {
      return (
        <div className='row center'>
          <img width='50%' height='50%' src={client.customImage} alt="Missing"/>
        </div>
      )
    }
  }

  render () {
    return (
      <div>
        {this.renderClientList()}
      </div>
    )
  }
}
