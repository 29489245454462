import React from 'react'

const NoMatch = () => {
  return (
    <div className='fourOhFour'>
      <div className='App text404' text404>
        <h1>Page Not Found</h1>
        <p>The page you're trying to reach does not exist</p>
        <p>Check the address, and try again</p>
      </div>
    </div>
  )
}

export default NoMatch
